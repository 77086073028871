import React from 'react'

import './Programs.css'
import {programsData} from '../../data/programsData'
import RightArrow from '../../assets/images/rightArrow.png'

const Programs = () => {
  return (
    <div className="programs" id="programs">
        {/* header */}
        <div className="programs-header">
            <span className='stroke-text'>Explora nuestros</span>
            <span>programas</span>
            <span className='stroke-text'>de Entrenamiento</span>
        </div>

        <div className="program-categories">
            {programsData.map((program) =>
                <div className="category">
                    {program.image}
                    <span>{program.heading}</span>
                    <span>{program.details}</span>
                    <div className="join-now">
                        {/* <span>Unete</span><img src={RightArrow} alt="" /> */}
                    </div>
                </div>
            )}
        </div>
    </div>
  )
}

export default Programs
