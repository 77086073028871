export const programsData = [  
  {
    image: <svg width="16" height="17" viewBox="0 0 16 17"  xmlns="http://www.w3.org/2000/svg">
    <path d="M9.25149 3.5206C9.47237 3.16294 9.8423 2.94938 10.2122 3.23412L11.5429 4.09183C12.1337 4.52068 11.7638 5.02071 11.6165 5.23601L8.29074 10.4551C8.14349 10.6687 7.92081 10.7399 7.69993 10.5975C7.69993 10.5975 7.6263 10.5263 6.66556 9.95336C5.70482 9.38214 5.9257 8.5227 6.44288 7.665L9.25149 3.5206Z" />
    <path d="M12.0667 0C13.0621 0 13.8667 0.806087 13.8667 1.79927C13.8667 2.7942 13.0603 3.59854 12.0667 3.59854C11.0714 3.59854 10.2667 2.79245 10.2667 1.79927C10.265 0.806087 11.0714 0 12.0667 0Z" />
    <path d="M9.97746 3.10001L7.20057 2.16333C6.98232 2.09155 6.68955 2.09155 6.54228 2.16333L3.39987 3.82134C2.9616 4.03844 2.81433 4.54266 3.03435 4.90333C3.17985 5.19221 3.39987 5.33577 3.69264 5.33577C3.83814 5.33577 3.98541 5.33577 4.13091 5.26399L6.98055 3.82134L9.46467 4.68623C9.90293 4.82979 10.3412 4.61445 10.4885 4.182C10.634 3.67602 10.4157 3.24357 9.97746 3.10001Z" />
    <path d="M5.3539 9.67883L4.41385 10.969H1.01385C0.507804 10.969 0 11.399 0 11.9005C0 12.4751 0.434005 12.9051 1.01209 12.9051H4.99194C5.3539 12.9051 5.64382 12.7623 5.85995 12.4037L6.8 11.0421C6.72972 11.0404 5.4277 10.1803 5.3539 9.67883Z" />
    <path d="M11.2044 10.8572L7.69352 8.35736C7.25489 8.07112 6.59604 8.14224 6.3048 8.6436C6.01179 9.07209 6.0846 9.71396 6.59782 10.0002L9.37703 12.0004L7.6953 15.6434C7.47509 16.1431 7.6953 16.7155 8.20674 16.9289C8.35236 17 8.49976 17 8.64538 17C9.0112 17 9.37703 16.7867 9.52265 16.4293L11.4974 12.0004C11.7158 11.643 11.5702 11.1417 11.2044 10.8572Z" />
    <path d="M15.3746 7.67116L12.66 7.0347L11.8525 4.63298C11.7064 4.20924 11.2661 3.99652 10.8258 4.13891C10.3856 4.27958 10.1646 4.70503 10.3125 5.12876L11.2661 7.95422C11.3392 8.23728 11.5602 8.44829 11.8525 8.51863L15.0092 9.29576H15.0823C15.5226 9.36609 15.8898 9.08475 15.9628 8.66102C16.1072 8.16523 15.8149 7.7415 15.3746 7.67116Z" />
    </svg>,
    heading: "CALISTENIA",
    details:
      "Nace como una rama de la gimnasia olímpica, la cual se basa en entrenamiento funcional y fuerza utilizando el peso corporal al iniciar y luego se incrementa agregando peso al peso corporal dependiendo el enfoque del atleta. Busca incrementar tus habilidades y el dominio de tu cuerpo. .",
  },
  {
    image: <svg width="12" height="16" viewBox="0 0 12 16"  xmlns="http://www.w3.org/2000/svg">
    <path d="M10.3226 13.9301C9.09534 15.2434 7.39179 16 5.64653 16C5.18413 16 4.72174 15.9433 4.27326 15.8367C3.03558 15.5434 1.97869 14.8967 1.12691 13.9135C1.0991 13.8801 -1.25805 10.9803 0.928746 7.52058C1.04695 7.33726 1.17906 7.1706 1.28336 7.04395C2.23596 5.89402 3.16769 4.30413 2.6601 3.60085C2.62072 3.54516 2.59996 3.47929 2.60058 3.41201C2.60121 3.34473 2.62318 3.27922 2.66358 3.22421C2.74354 3.10755 2.89304 3.05422 3.0321 3.09088C3.16769 3.12088 4.21763 3.43753 4.44361 5.43072C4.67307 4.96409 4.87471 4.3408 4.82604 3.63085C4.75303 2.55425 4.13767 1.53099 2.99386 0.584388C2.93401 0.53482 2.89375 0.467037 2.87983 0.392398C2.8659 0.31776 2.87916 0.2408 2.91737 0.174416C2.99386 0.0377589 3.16422 -0.0322363 3.31719 0.0144272C3.39715 0.0344258 10.8267 2.19428 9.70027 9.57711C10.1348 9.13047 10.6181 8.26386 10.4999 6.6773C10.4895 6.52065 10.5903 6.38066 10.7433 6.33399C10.8997 6.28733 11.0666 6.35066 11.1465 6.48398C11.1709 6.52398 13.5176 10.507 10.3226 13.9301Z" />
    </svg>,
    heading: "SPARTAN FITNESS",
    details:
      "El programa se compone de entrenamiento de pesas moderno con apoyo de Bodyweight training y Condición física con bases de less mill program y CrossFit. Este programa está enfocado en el  aumento de masa muscular, tonificación, reduccion del porcentaje de grasa corporal y  perdida sana de peso.",
  }, 
  {
    image: <svg width="20" height="16" viewBox="0 0 20 16"  xmlns="http://www.w3.org/2000/svg">
    <path d="M19 6.31132H16.5C16.3677 6.31187 16.2368 6.33867 16.1149 6.39017C15.9931 6.44166 15.8826 6.51683 15.79 6.61132L14.55 7.90132L11.75 4.71132C11.6565 4.61141 11.5434 4.53177 11.4179 4.47732C11.2923 4.42287 11.1569 4.39477 11.02 4.39477C10.8831 4.39477 10.7477 4.42287 10.6222 4.47732C10.4966 4.53177 10.3836 4.61141 10.29 4.71132L8.59001 6.41132H7.00001C6.7348 6.41132 6.48044 6.51668 6.2929 6.70422C6.10537 6.89175 6.00001 7.14611 6.00001 7.41132C6.00001 7.67654 6.10537 7.93089 6.2929 8.11843C6.48044 8.30597 6.7348 8.41132 7.00001 8.41132H9.00001C9.13162 8.41208 9.26208 8.38686 9.38392 8.33709C9.50576 8.28733 9.61657 8.214 9.71001 8.12132L11 6.78132L13.8 9.97132C13.8903 10.0743 14.0009 10.1576 14.1247 10.2161C14.2486 10.2746 14.3831 10.307 14.52 10.3113C14.6516 10.3121 14.7821 10.2869 14.9039 10.2371C15.0258 10.1873 15.1366 10.114 15.23 10.0213L16.93 8.31132H19C19.2652
    8.31132 19.5196 8.20597 19.7071 8.01843C19.8947 7.83089 20 7.57654 20 7.31132C20 7.04611 19.8947 6.79175 19.7071 6.60422C19.5196 6.41668 19.2652 6.31132 19 6.31132ZM11.61 11.6113L9.71001 13.5113C9.61705 13.6051 9.50645 13.6794 9.38459 13.7302C9.26273 13.781 9.13202 13.8071 9.00001 13.8071C8.868 13.8071 8.73729 13.781 8.61543 13.7302C8.49358 13.6794 8.38297 13.6051 8.29001 13.5113L3.08001 8.30132C2.73713 7.95865 2.46513 7.55177 2.27955 7.10393C2.09398 6.6561 1.99846 6.17608 1.99846 5.69132C1.99846 5.20656 2.09398 4.72655 
    2.27955 4.27871C2.46513 3.83088 2.73713 3.424 3.08001 3.08132C3.77167 2.39189 4.70843 2.00476 5.68501 2.00476C6.66159 2.00476 7.59835 2.39189 8.29001 3.08132C8.38297 3.17505 8.49358 3.24945 8.61543 3.30021C8.73729 3.35098 8.868 3.37712 9.00001 3.37712C9.13202 3.37712 9.26273 3.35098 9.38459 3.30021C9.50645 3.24945 9.61705 3.17505 9.71001 3.08132C10.4127 2.41304 11.3453 2.04037 12.315 2.04037C13.2847 2.04037 14.2173 2.41304 14.92 3.08132C15.1428 3.30707 15.3376 3.55889 15.5 3.83132C15.6456 4.03154 15.8603 4.17062 16.1026 4.22167C16.3448 4.27272 16.5974 4.2321 16.8114 4.10767C17.0254 3.98324 17.1856 3.78385 17.2611 3.54808C17.3366 3.3123 17.322 3.05691 17.22 2.83132C16.9728 2.42087 16.6774 2.0415 16.34 1.70132C15.391 0.741174 14.1311 0.150426 12.786 0.0349788C11.4409 -0.0804685 10.0988 0.286933 9.00001 1.07132C8.15087 0.459617 7.14924 0.0943988 6.10565 0.0159735C5.06206 -0.0624518 4.0171 0.148965 3.08607 0.626892C2.15504 1.10482 1.37416 1.83067 0.829583 2.72435C0.28501 3.61804 -0.00207453 4.6448 1.12854e-05 5.69132C-0.000288296 6.43541 0.147178 7.17216 0.433854 7.85881C0.72053 8.54546 1.14071 9.16834 1.67001 9.69132L6.88001 14.9013C7.44251 15.4631 8.20501 15.7787 9.00001 15.7787C9.79501 15.7787 10.5575 15.4631 11.12 14.9013L13 13.0213C13.1863 12.834 13.2908 12.5805 13.2908 12.3163C13.2908 12.0521 13.1863 11.7987 13 11.6113C12.8135 11.4311 12.5644 11.3303 12.305 11.3303C12.0457 11.3303 11.7965 11.4311 11.61 11.6113Z" />
    </svg>,
    heading: "ARTES MARCIALES MIXTAS",
    details:
      "Sistema híbrido de MMA acoplado para todas las edades y niveles, contamos con programas para niños desde 5 años hasta adolescentes de 17 años. Este sistema de artes marciales te permite aprender de una manera sana y  segura con nuestro poderoso sistema de filosofía. En nuestro centro de Entrenamiento también contamos con un equipo de Competencia el más grande y mejor de Santa Rosa y de los competitivos a nivel nacional.",
  },
];



