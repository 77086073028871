import React from 'react'

import Header from '../Header/Header'

import './Hero.css'

import hero_image from "../../assets/images/hero_image.png";
import hero_image_back from "../../assets/images/hero_image_back.png";
import Heart from "../../assets/images/heart.png";
import Calories from "../../assets/images/calories.png";

import {motion} from 'framer-motion'
import NumberCounter from 'number-counter';

const Hero = () => {
    const transition = {type: 'spring', duration: 3}
    const mobile = window.innerWidth <= 768 ? true : false;

    return (
    <div className="hero" id='hero'>

        <div className="blur hero-blur"></div>
        <div className="left-h">
            <Header/>
            {/* El Mejor Gym */}
            <div className="the-best-ad">
                <motion.div
                initial = {{left: mobile? '158' :'210px'}}
                whileInView = {{left: '8px'}}
                transition = {{...transition, type: 'tween'}}
                ></motion.div>
                <span>el mejor gimnasio de la region</span>
            </div>
            {/* Heagind */}
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Haz de tu  </span>
                    <span>Cuerpo </span>
                    <span>Un Aliado</span>
                </div>
                <div>
                    {/* <span>Un Aliado</span> */}
                </div>
                <div>
                    <span>
                        Aquí te ayudaremos a dar forma y construir tu cuerpo ideal y vivir tu vida al máximo.
                    </span>                    
                </div>
            </div>

            {/* figures */}
            <div className="figures">
                <div>
                    <span>
                        <NumberCounter end={300} start={270} delay="10" preFix="+" />                       
                    </span>
                    <span>ejercicios</span>
                </div>
                <div>
                    <span>
                        <NumberCounter end={200} start={170} delay="60" preFix="+" /> 
                    </span>
                    <span>clientes</span>
                </div>
                <div>
                    <span>
                        <NumberCounter end={900} start={870} delay="70" preFix="+" />
                    </span>
                    <span>rutinas</span>
                </div>                
            </div>

            {/* buttons */}

            <div className="hero-buttons">
                <button className="btn">Empezar</button>
                <button className="btn">Aprende Mas</button>
            </div>

        </div>
        <div className="right-h">
            {/* <button className='btn'>Iniciar Sesion</button> */}             

            {/* images */}
            <img src={hero_image} alt="" className='hero-image'/>
            <motion.img 
                initial = {{right: "11rem"}}
                whileInView = {{right: "25rem"}}
                transition = {transition}
            src={hero_image_back} alt="" className='hero-image-back'/>

            {/* calories */}
            <motion.div 
                initial = {{right: "60rem"}}
                whileInView = {{right: "40rem"}}
                transition = {transition}
                className="calories">
                <img src={Calories} alt="" />
                <div>
                    <span>Calorias Quemadas</span>
                    <span>850 kcal</span>
                </div>                
            </motion.div>


        </div>
        
    </div>
  )
}

export default Hero