import React, { useRef } from 'react'

import './Join.css'
import emailjs from '@emailjs/browser';

const Join = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_8u2nd9g', 'template_h0q2otl', form.current, 'GkmYM0JYW8PMGcgr6')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

  return (
    <div className="Join" id="join-us">
        <div className="left-j">
            <hr />
            <div>
                <span className='stroke-text'>Listo para</span>                
            </div>
            <div>
                <span>Subir de Nivel</span>
            </div>
            <div>
                <span>con</span>
                <span className='stroke-text'>Nosotros?</span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} className='email-container' onSubmit={sendEmail}>
                <input type="email" name='user_email' placeholder='Ingresa tu correo'/>
                <button className='btn btn-j'>Unete ahora</button>
            </form>
        </div>
    </div>
  )
}

export default Join