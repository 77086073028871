import React from 'react'

import {plansData} from '../../data/plansData'

import './Plans.css'
import RightArrow from '../../assets/images/rightArrow.png'
import whiteTick from '../../assets/images/whiteTick.png'

const Plans = () => {
  return (
    <div className="plans-container">
      <div className="blur plans-blur-1"></div>
      <div className="blur plans-blur-2"></div>
      <div className="plans-header">
        <span className='stroke-text'>¿LISTO PARA EMPEZAR</span>
        <span>TU VIAJE</span>
        <span className='stroke-text'>CON NOSOTROS?</span>
      </div>

      {/* Plans Card */}
      <div className="plans">
        {plansData.map((plan, i) =>(
          <div className="plan" key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>Q. {plan.price}</span>

            <div className="features">
              {plan.features.map((feature, i) =>
                <div className="feature">
                  <img src={whiteTick} alt="" />
                  <span key={i}>{feature}</span>
                </div>
              )}
            </div>

            {/* <div>
              <span>Ver mas beneficios -></span>
            </div>
            <button className="btn">Inscribirme</button> */}
          </div>          
        ))}
      </div>
    </div>
  )
}

export default Plans
