import React from 'react'
import { Carousel } from 'react-carousel-minimal'

import './Reasons.css'

import image1 from '../../assets/images/image1.png'
import image2 from '../../assets/images/image2.png'
import image3 from '../../assets/images/image3.png'
import image4 from '../../assets/images/image4.png'
import nb from '../../assets/images/nb.png'
import adidas from '../../assets/images/adidas.png'
import nike from '../../assets/images/nike.png'
import tick from '../../assets/images/tick.png'


const Reasons = () => {

  const data = [
    {
      image: "https://i.postimg.cc/W3qwZ1tR/DSC1469.jpg",
      // caption: "111"
    },
    {
      image: "https://i.postimg.cc/9XSBcT8W/DSC1480.jpg",
      // caption: "Scotland"
    },
    {
      image: "https://i.postimg.cc/1XYzxwD2/DSC1573.jpg",
      // caption: "Darjeeling"
    },
    {
      image: "https://i.postimg.cc/dtbnjdrW/DSC1626.jpg",
      // caption: "San Francisco"
    },    
    {
      image: "https://i.postimg.cc/SKjV894F/DSC1404.jpg",
      // caption: "San Francisco"
    },
    {
      image: "https://i.postimg.cc/sxZ5DP5K/DSC1518.jpg",
      // caption: "San Francisco"
    },
    {
      image: "https://i.postimg.cc/fLGdVtzK/DSC1394.jpg",
      // caption: "San Francisco"
    },
    {
      image: "https://i.postimg.cc/mkXZqKP5/DSC1406.jpg",
      // caption: "San Francisco"
    },
    {
      image: "https://i.postimg.cc/RZCyqqbK/DSC1533.jpg",
      // caption: "San Francisco"
    },
    {
      image: "https://i.postimg.cc/76Tgx8vj/DSC1642.jpg",
      // caption: "San Francisco"
    }
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  return (
    <div className="reasons" id='reasons'>
      <div className="left-r">          
          <div style={{ textAlign: "center" }}>            
            <div style={{
              padding: "0 20px"
            }}>
              <Carousel
                data={data}
                time={3000}
                width="850px"
                height="500px"
                captionStyle={captionStyle}
                radius="10px"
                slideNumber={true}
                slideNumberStyle={slideNumberStyle}
                captionPosition="bottom"
                automatic={true}
                dots={true}
                pauseIconColor="white"
                pauseIconSize="40px"
                slideBackgroundColor="darkgrey"
                slideImageFit="cover"
                thumbnails={true}
                thumbnailWidth="100px"
                style={{
                  textAlign: "center",
                  maxWidth: "850px",
                  maxHeight: "500px",
                  margin: "40px auto",
                }}
              />
            </div>
          </div>

      </div>

      <div className="right-r">
          {/* <span>Some Reasons</span> */}
          <div>
            <span className='stroke-text t-mobile'>¿POR QUÉ</span>
            <span className='t-mobile'> ELEGIRNOS?</span>
          </div>
          <div className='details-r'>
            <div>
              <img src={tick} alt="" />
              <span>ENTRENADORES EXPERTOS CAPACITANDOSE CONSTANTEMENTE.</span>
            </div>
            <div>
              <img src={tick} alt="" />
              <span>ENTRENOS INTELIGENTES ACOPLADOS A TU TIEMPO DISPONIBLE.</span>
            </div>
            <div>
              <img src={tick} alt="" />
              <span>PROGRAMAS PERSONALIZADOS.</span>
            </div>
            <div>
              <img src={tick} alt="" />
              <span>CLASES PRIVADAS.</span>
            </div>
          </div>
          {/* <span
            style={{
              color: "var(--gray)",
              fontWeight: "normal",
            }}
          >
            Patrocinadores
          </span>
          <div className="partners">
            <img src={nb} alt="" />
            <img src={adidas} alt="" />
            <img src={nike} alt="" />
          </div> */}
      </div>
    </div>
  )
}

export default Reasons
