import React from 'react'

import './Footer.css'
import gitHub from "../../assets/images/github.png"
import Instragram from "../../assets/images/instagram.png"
import LinkedIn from "../../assets/images/linkedin.png"
import Logo from "../../assets/images/logo.png"

const Footer = () => {
  return (
    <div className="footer-container">
        <hr />
        <div className="footer">
            {/* <div className="social-links">
                <img src={gitHub} alt="" />
                <img src={Instragram} alt="" />
                <img src={LinkedIn} alt="" />
            </div> */}
            <div className="log-f">
                <img src={Logo} alt="" width={250}/>
            </div>
        </div>
        <div className="blur blur-f-1"></div>     
        <div className="blur blur-f-2"></div>     
    </div>
  )
}

export default Footer
